import React, { useEffect, useState } from 'react';
import "@fontsource/inter/latin.css"
import Layout from '../components/layout';
import { css } from '@emotion/react';
import AnimationHeader from '../components/landing/animation_header';
import MoreToCome from '../components/landing/more_to_come';
import PartnerMarquee from '../components/landing/partner_marquee';
import Introduction from '../components/landing/introduction';
import OverviewList from '../components/landing/overview_list';
import GeneralFooter from '../components/general_footer';
import { color } from '../components/defaults/styles/theme';


const Open = () => {
  const [height, setHeight] = useState(null)

  useEffect(() => {
    setHeight(window.innerHeight)
  }, [])

  const GradientOverlay = () => {
    return <div css={css`
      position: relative;
      height: 0px;
      z-index: 2;
    `}>
      <div css={css`
        width: 100%;
        height: ${height ? height / 10 + "px" : "calc(100vh / 10)"};
        background: linear-gradient(
          to bottom,
          hsla(0, 0%, 0%, 0) 0%,
          hsla(0, 0%, 0%, 0.013) 8.1%,
          hsla(0, 0%, 0%, 0.049) 15.6%,
          hsla(0, 0%, 0%, 0.104) 22.6%,
          hsla(0, 0%, 0%, 0.175) 29.2%,
          hsla(0, 0%, 0%, 0.259) 35.5%,
          hsla(0, 0%, 0%, 0.352) 41.5%,
          hsla(0, 0%, 0%, 0.45) 47.4%,
          hsla(0, 0%, 0%, 0.55) 53.3%,
          hsla(0, 0%, 0%, 0.648) 59.2%,
          hsla(0, 0%, 0%, 0.741) 65.2%,
          hsla(0, 0%, 0%, 0.825) 71.4%,
          hsla(0, 0%, 0%, 0.896) 77.9%,
          hsla(0, 0%, 0%, 0.951) 84.8%,
          hsla(0, 0%, 0%, 0.987) 92.1%,
          hsl(0, 0%, 0%) 100%
        );
        transform: translateY(-100%);
      `} />
    </div>
  }

  return (
    <Layout dark>
      <AnimationHeader />
      <GradientOverlay />
      <Introduction />
      <OverviewList />
      <MoreToCome windowHeight={height} />
      <PartnerMarquee />
      <GeneralFooter theme={color.richBlack} />
    </ Layout>
  )
}

export default Open;
import React from 'react';
import { css } from '@emotion/react';
import { wrapper, ArrowLink } from '../defaults/styles/elements';
import { color } from '../defaults/styles/theme';
import mq from '../defaults/styles/mediaquerys';
import PnpEye from '../defaults/assets/svg/pnp12.svg'
import PnpEmployee from '../defaults/assets/svg/pnp10.svg'
import PnpPlane from '../defaults/assets/svg/pnpX4.svg'
import Flags from '../../data/raw/flags';

const data = [
  {
    icon: PnpEye,
    title: "Grand Opening",
    day: 1,
    when: "July 21st, 2021 | 03:00 – 05:30 PM (CEST)",
    content: "Together we're celebrating 5 incredible years of open innovation at STARTUP AUTOBAHN. Throughout our Grand Opening stream, we will hear from top industry level voices and highlight some of the most innovative, game-changing technologies that have graduated from our program.",
    where: "This does not require you to login anywhere, you can simply watch the stream via this event page.",
    url: "/agenda/day1",
  },
  {
    icon: PnpEmployee,
    title: "Tech Deep Dive",
    day: 2,
    when: "July 22nd, 2021 | 01:00 – 18:00 PM (CEST)",
    raw_date: "2021-07-22",
    content: "Together with our partners and 29 Program 10 startups, we will deep dive into the newest technologies in the fields of Mobility, Production, Enterprise, Sustainability and beyond. Hop along this virtual ride for a day of technology-focused breakout sessions, small group discussions, curated networking, and the opportunity to meet and discuss future collaborations with our program startups and corporate partners.",
    where: "On Day 2 we will use the tool Hopin to give you the best opportunity to network and to jump from one breakout session to the next. You will get access via this webpage closer to the event launch.",
    url: "/agenda/day2",
    hopin: "https://hopin.com/events/startup-autobahn-expo10"
  },
  {
    icon: PnpPlane,
    title: "The Finale",
    day: 3,
    when: "July 23rd, 2021 | 14:00 – 14:45 AM (CEST)",
    content: "Join our Inspiration finale on Friday morning. Markus Schäfer, Member of the Board of Management of Daimler AG and Mercedes-Benz AG, will talk with Olympic Champion & 3x Ironman World Champion, Jan Frodeno, about what it means to innovate and defy the status quo.",
    where: "This does not require you to login anywhere, you can simply watch the stream via this event page",
    url: "/agenda/day3",
  },
]


const OverviewItem = ({e}) => {
  const {icon: Icon, title, content, url, day, when, where, hopin, raw_date} = e
  
  return (
    <div css={css`
      display: flex;
      color: ${color.main_light};
      flex-direction: column;
      align-items: center;
      max-width: 35em;
      margin: 0 auto;
      &:not(:first-of-type) {
        margin-top: 7em;
      }
      ${mq[2]} {
        flex-direction: row;
        align-items: baseline;
        max-width: unset;
      }
    `}>

      <div css={css`
        display: none;
        ${mq[2]} {
          display: block;
          flex: 0 0 10rem;
          transform: translateY(calc(50% - 1em));
          svg {
            fill: white;
            height: 7rem;
            width: auto;
          }
        }
      `}>
        <Icon />
      </div>

      <div css={css`
        display: flex;
        flex-direction: column;
        align-items: center;
        ${mq[2]} {
         align-items: flex-start; 
        }
      `}>
        <h3 css={css`
          margin-bottom: 0;
          font-size: 1.75em;
          font-weight: 600;
          letter-spacing: .01em;
          line-height: 1.2862em;
          margin-bottom: 1em;
          text-align: center;
          ${mq[2]} {
            text-align: left;
          }
        `}>
          {day && (
            <span css={css`
              color: ${color.gray700};
            `}>Day {day}:{" "}
              <br css={css`
                ${mq[1]} {
                  display: none;
                }
              `} />
            </span>
          )}
          {title}
        </h3>
        
        <p css={css`
          font-size: 1em;
          line-height: 1.6em;
          letter-spacing: 0.02em;
          color: ${color.grey400};
          margin-bottom: 0;
          text-align: center;
          ${mq[2]} {
            text-align: left;
          }
        `}>{content}</p>

        {when || where ? (
          <div css={css`
            margin-top: .5em;
            max-width: 30em;
            text-align: center;
            ${mq[2]} {
              text-align: left;
            }
          `}>
            {when && (<p css={css`
              font-size: .75em;
              margin-bottom: 0;
              letter-spacing: .03em;
            `}>
              <span css={css`
                color: ${color.gray700};
                display: block;
              `}>When:</span>
              {when}
            </p>)} 
            
            {where && (<p css={css`
              font-size: .75em;
              margin-bottom: 0;
              letter-spacing: .03em;
              :not(:first-of-type) {
                margin-top: .5em;
              }
            `}>
              <span css={css`
                color: ${color.gray700};
                display: block;
              `}>Where: </span>
              {where}
            </p>)}
          </div>
        ) : null}

        {hopin && raw_date >= Flags.settings.live.date && (
          <ArrowLink
            url={hopin}
            text="Pre-Register for Day 2 on hopin.com"
            css={css`
              margin-top: 1em;
            `}
          />
        )}

        {url && (
          <ArrowLink
            url={url}
            text="Find out more"
            css={css`
              margin-top: 1em;
            `}
          />
        )}
        
      </div>

    </div>
  )
}


const OverviewList = () => {
  
  return (
    <section css={css`
      background: ${color.richBlack};
      padding: 5rem 0;
    `}>
      <div css={[wrapper, css`
        max-width: 55rem;
      `]}>
        {data.map((e, index) => (
          <OverviewItem e={e} key={index}/>
        ))}
      </div>
    </section>
  )
}

export default OverviewList;